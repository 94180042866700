<template>
    <v-app id="scenario">
        <NavigationDrawer v-if="user" class="my-10 mx-10"></NavigationDrawer>

        <PopupAlert></PopupAlert>

        <v-main :class="backgroundColor">
            <router-view class="mx-5 my-7"></router-view>
        </v-main>
    </v-app>
</template>

<script>
import NavigationDrawer from "@/components/NavigationDrawer";
import PopupAlert from "@/components/PopupAlert";
import { mapActions, mapState } from "vuex";

export default {
    name: "App",


    components: {
        NavigationDrawer,
        PopupAlert,
    },

    computed: {
        ...mapState("formations", ["currentFormation"]),
        ...mapState("synopsis", ["currentSynopsis"]),

        ...mapState("settings", ["user"]),

        backgroundColor: function() {
            const loginPages = ["Login", "Register", "Forgot"]
            if(loginPages.includes(this.$route.name)) {
                return "grey darken-2";
            }
            return "background";
        }
    },

    methods: {
        ...mapActions("formations", ["setCurrentFormation"]),
        ...mapActions("synopsis", ["setCurrentSynopsis"]),
    }
};
</script>

<style>
.v-main__wrap {
    margin: 0 85px;
}
</style>
