export default [
    {
        path: "/login",
        name: "Login",
        component: () => import("@/views/global/Login"),
        meta: {
            requiresAuth: false,

            title: "Connexion",
        },
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("@/views/global/Register"),
        meta: {
            requiresAuth: false,

            title: "Inscription",
        },
    },
    {
        path: "/forgot",
        name: "Forgot",
        component: () => import("@/views/global/Forgot"),
        meta: {
            requiresAuth: false,

            title: "Mot de passe perdu",
        },
    },
    {
        path: "/color",
        name: "Color",
        component: () => import("@/views/global/Color"),
        meta: { requiresAuth: false },
    },
    {
        path: "/",
        name: "Home",
        component: () => import("@/views/global/Home"),
        meta: {
            requiresAuth: true,

            title: "Méthodologie",
        },
    },
    {
        path: "/profil",
        name: "Profile",
        component: () => import("@/views/global/Profile"),
        meta: {
            requiresAuth: true,

            title: "Profil",
        },
    },
    {
        path: "/subscription",
        name: "Subscription",
        component: () => import("@/views/global/Subscription"),
        meta: {
            requiresAuth: true,

            title: "Abonnement",
        },
    },
];
