const axios = require("axios");
axios.defaults.baseURL = process.env.VUE_APP_API_SERVER_URL;

export default {
    async initStore({ commit }) {
        try {
            const result = await axios.get("/formations");
            commit("setFormationList", result.data);
        } catch (e) {
            console.warn(e);
        }
    },

    async getFormations({ commit }) {
        let formations = [];

        try {
            const result = await axios.get("/formations");
            formations = result.data;
        } catch (e) {
            console.warn(e);
        }

        commit("setFormationList", formations);
    },

    async addFormation({ commit, rootState }, title) {
        try {
            const result = await axios.post("/formations/", {
                title: title,
                owner: rootState.settings.user.id,
            });

            commit("addFormation", {
                title: title,
                id: result.data,
                owner: rootState.settings.user.id,
            });

            return result.data;
        } catch (e) {
            console.warn(e);
        }
    },

    async duplicateFormation({ dispatch }, formationId) {
        try {
            const result = await axios.post("/formations/", {
                formationId,
            });

            await dispatch("getFormations");

            return result.data;
        } catch (e) {
            console.warn(e);
        }
    },

    async modifyFormation({ commit, state }, modifications) {
        try {
            const result = await axios.put("/formations/" + state.currentFormation, {
                formationId: state.currentFormation,
                ...modifications,
            });

            commit("modifyFormation", modifications);
        } catch (e) {
            console.warn(e);
        }
    },

    // Add actions
    async addEmptyEducationalObjective({ commit, getters, state }) {
        try {
            const newOrder = getters.getNewEducationalObjectiveOrder;

            const result = await axios.post("/formations/" + state.currentFormation + "/educational", {
                formationId: state.currentFormation,
                order: newOrder,
            });

            commit("addEducationalObjective", {
                id: result.data,
                order: newOrder,
            });
        } catch (e) {
            console.warn(e);
        }
    },

    async addEmptyOperationalObjective({ commit, getters, state }, educationalId) {
        try {
            const newOrder = getters.getNewOperationalObjectiveOrder(educationalId);

            const result = await axios.post("/formations/" + state.currentFormation + "/educational/" + educationalId + "/operational", {
                formationId: state.currentFormation,
                educationalObjectiveId: educationalId,
                order: newOrder,
            });

            commit("addOperationalObjective", {
                id: result.data,
                educationalObjectiveId: educationalId,
                order: newOrder,
            });
        } catch (e) {
            console.warn(e);
        }
    },

    async addEmptySpecificObjective({ commit, getters, state }, operationalId) {
        try {
            const newOrder = getters.getNewSpecificObjectiveOrder(operationalId);

            const result = await axios.post("/formations/" + state.currentFormation + "/operational/" + operationalId + "/specific", {
                formationId: state.currentFormation,
                operationalObjectiveId: operationalId,
                order: newOrder,
            });

            commit("addSpecificObjective", {
                id: result.data,
                operationalIdObjectiveId: operationalId,
                order: newOrder,
            });
        } catch (e) {
            console.warn(e);
        }
    },

    addEducationalObjective({ commit }, objective) {
        commit("addEducationalObjective", objective);
    },

    async addOperationalObjective({ commit, getters, state }, objective) {
        try {
            objective.order = getters.getNewOperationalObjectiveOrder(objective.educationalObjectiveId);

            const result = await axios.post(
                "/formations/" + state.currentFormation + "/educational/" + objective.educationalObjectiveId + "/operational",
                {
                    formationId: state.currentFormation,
                    ...objective,
                },
            );

            commit("addOperationalObjective", {
                ...objective,
                id: result.data,
            });
        } catch (e) {
            console.warn(e);
        }
    },

    async addSpecificObjective({ commit, getters, state }, objective) {
        try {
            objective.order = getters.getNewSpecificObjectiveOrder(objective.operationalObjectiveId);

            const result = await axios.post(
                "/formations/" + state.currentFormation + "/operational/" + objective.operationalObjectiveId + "/specific",
                {
                    formationId: state.currentFormation,
                    ...objective,
                },
            );

            commit("addSpecificObjective", {
                ...objective,
                id: result.data,
            });
        } catch (e) {
            console.warn(e);
        }
    },

    // Modify actions
    async modifyEducationalObjective({ commit, state }, modifications) {
        try {
            const result = await axios.put("/formations/" + state.currentFormation + "/educational/" + modifications.id, {
                formationId: state.currentFormation,
                ...modifications,
            });

            commit("modifyEducationalObjective", modifications);
        } catch (e) {
            console.warn(e);
        }
    },

    async modifyOperationalObjective({ commit, state }, modifications) {
        try {
            const result = await axios.put("/formations/" + state.currentFormation + "/operational/" + modifications.id, {
                formationId: state.currentFormation,
                ...modifications,
            });

            commit("modifyOperationalObjective", modifications);
        } catch (e) {
            console.warn(e);
        }
    },

    async modifySpecificObjective({ commit, state }, modifications) {
        try {
            const result = await axios.put("/formations/" + state.currentFormation + "/specific/" + modifications.id, {
                formationId: state.currentFormation,
                ...modifications,
            });

            commit("modifySpecificObjective", modifications);
        } catch (e) {
            console.warn(e);
        }
    },

    async modifyTutoringTask({ commit, state }, modifications) {
        try {
            const result = await axios.put("/formations/" + state.currentFormation + "/tutoring/" + modifications.id, {
                formationId: state.currentFormation,
                ...modifications,
            });

            // commit("modifyTutoringTask", modifications);
        } catch (e) {
            console.warn(e);
        }
    },

    // Remove Actions
    async removeFormation({ commit }, id) {
        try {
            await axios.delete("/formations/" + id);

            commit("removeFormation", id);
        } catch (e) {
            console.warn(e);
        }
    },

    async removeEducationalObjective({ commit, state }, id) {
        try {
            await axios.delete("/formations/" + state.currentFormation + "/educational/" + id);

            commit("removeEducationalObjective", id);
        } catch (e) {
            console.warn(e);
        }
    },

    async removeOperationalObjective({ commit, state }, id) {
        try {
            await axios.delete("/formations/" + state.currentFormation + "/operational/" + id);

            commit("removeOperationalObjective", id);
        } catch (e) {
            console.warn(e);
        }
    },

    async removeSpecificObjective({ commit, state }, id) {
        try {
            await axios.delete("/formations/" + state.currentFormation + "/specific/" + id);

            commit("removeSpecificObjective", id);
        } catch (e) {
            console.warn(e);
        }
    },

    // Others
    async setCurrentFormation({ commit, dispatch, state }, formationId) {
        if (state.formations.length === 0) {
            await dispatch("getFormations");
        }
        commit("synopsis/setCurrentSynopsis", null, { root: true });
        commit("setCurrentFormation", formationId);

        try {
            let result = await axios.get("/formations/" + formationId + "/educational");
            const educationals = result.data;

            result = await axios.get("/formations/" + formationId + "/operational");
            const operationals = result.data;

            result = await axios.get("/formations/" + formationId + "/specific");
            const specifics = result.data;

            operationals.forEach((obj) => {
                obj.classDuration = 0;
                obj.learnerDuration = 0;
                obj.tutoringDuration = 0;
                obj.productionDuration = 0;

                const elements = specifics.filter((item) => item.operationalObjectiveId === obj.id);

                for (const index in elements) {
                    obj.classDuration += elements[index].classDuration;
                    obj.learnerDuration += elements[index].learnerDuration;
                    obj.tutoringDuration += elements[index].TutoringTask.duration;
                    obj.productionDuration += elements[index].productionDuration;
                }
            });

            educationals.forEach((obj) => {
                obj.classDuration = 0;
                obj.learnerDuration = 0;
                obj.tutoringDuration = 0;
                obj.productionDuration = 0;

                const elements = operationals.filter((item) => item.educationalObjectiveId === obj.id);

                for (const index in elements) {
                    obj.classDuration += elements[index].classDuration;
                    obj.learnerDuration += elements[index].learnerDuration;
                    obj.tutoringDuration += elements[index].tutoringDuration;
                    obj.productionDuration += elements[index].productionDuration;
                }
            });

            commit("setEducationalList", educationals);
            commit("setOperationalList", operationals);
            commit("setSpecificList", specifics);

            dispatch("getVirtualClasses");
        } catch (e) {
            console.warn(e);
        }
    },

    async getVirtualClasses({ commit, state }) {
        let classes = [];

        try {
            const result = await axios.get("/formations/" + state.currentFormation + "/classes/");
            classes = result.data;
        } catch (e) {
            console.warn(e);
        }

        commit("setVirtualClassesList", classes);
    },

    async addVirtualClass({ commit, state, dispatch }, title) {
        try {
            const result = await axios.post("/formations/" + state.currentFormation + "/classes", {
                title: title,
                formationId: state.currentFormation,
            });

            commit("addVirtualClass", {
                title: title,
                formationId: state.currentFormation,
                id: result.data,
            });

            return result.data;
        } catch (e) {
            console.warn(e);
        }
    },

    async modifyVirtualClass({ commit, state }, modifications) {
        try {
            const result = await axios.put("/formations/" + state.currentFormation + "/classes/" + modifications.id, {
                formationId: state.currentFormation,
                ...modifications,
            });

            commit("modifyVirtualClass", modifications);
        } catch (e) {
            console.warn(e);
        }
    },

    async removeVirtualClass({ commit, state }, id) {
        try {
            await axios.delete("/formations/" + state.currentFormation + "/classes/" + id);

            commit("removeVirtualClass", id);
        } catch (e) {
            console.warn(e);
        }
    },

    async getVirtualClassElements({ commit, state }) {
        let elements = [];

        try {
            const result = await axios.get("/formations/" + state.currentFormation + "/classes/elements");
            elements = result.data;
        } catch (e) {
            console.warn(e);
        }

        commit("setVirtualClassElements", elements);
    },

    async addVirtualClassElement({ state, dispatch, getters }, { id, element }) {
        try {
            const newOrder = getters.getNewVirtualClassElementOrder(id);

            const result = await axios.post("/formations/" + state.currentFormation + "/classes/" + id + "/elements", {
                formationId: state.currentFormation,
                virtualClassId: id,
                order: newOrder,
                ...element,
            });

            dispatch("getVirtualClassElements", id);
        } catch (e) {
            console.warn(e);
        }
    },

    async modifyVirtualClassElement({ commit, state }, modifications) {
        try {
            const result = await axios.put(
                "/formations/" +
                    state.currentFormation +
                    "/classes/" +
                    modifications.virtualClassId +
                    "/elements/" +
                    modifications.virtualClassElementId,
                {
                    formationId: state.currentFormation,
                    ...modifications,
                },
            );

            commit("modifyVirtualClassElement", modifications);
        } catch (e) {
            console.warn(e);
        }
    },

    async getTeachingProcesses({ commit, state }) {
        let teachings = [];

        try {
            const result = await axios.get(`/formations/${state.currentFormation}/teachings/`);
            teachings = result.data;
        } catch (e) {
            console.warn(e);
        }

        commit("setTeachingProcessesList", teachings);
    },


    async addTeachingProcess({ commit, state, dispatch }, title) {
        try {
            const result = await axios.post(`/formations/${state.currentFormation}/teachings/`, {
                title: title,
                formationId: state.currentFormation,
            });

            commit("addTeachingProcess", {
                title: title,
                formationId: state.currentFormation,
                id: result.data,
            });

            return result.data;
        } catch (e) {
            console.warn(e);
        }
    },

    async modifyTeachingProcess({ commit, state }, modifications) {
        try {
            await axios.put(`/formations/${state.currentFormation}/teachings/${modifications.id}`, {
                formationId: state.currentFormation,
                ...modifications,
            });

            commit("modifyTeachingProcess", modifications);
        } catch (e) {
            console.warn(e);
        }
    },

    async removeTeachingProcess({ commit, state }, id) {
        try {
            await axios.delete(`/formations/${state.currentFormation}/teachings/${id}`);

            commit("removeTeachingProcess", id);
        } catch (e) {
            console.warn(e);
        }
    },

    async getTeachingProcessElements({ commit, state }) {
        let elements = [];

        try {
            const result = await axios.get(`/formations/${state.currentFormation}/teachings/elements`);
            elements = result.data;
        } catch (e) {
            console.warn(e);
        }

        commit("setTeachingProcessElements", elements);
    },

    async addTeachingProcessElement({ state, dispatch, getters }, { id, element }) {
        try {
            const newOrder = getters.getNewTeachingProcessElementOrder(id);

            await axios.post(`/formations/${state.currentFormation}/teachings/${id}/elements`, {
                formationId: state.currentFormation,
                teachingProcessId: id,
                order: newOrder,
                ...element,
            });

            dispatch("getTeachingProcessElements", id);
        } catch (e) {
            console.warn(e);
        }
    },

    async modifyTeachingProcessElement({ commit, state }, modifications) {
        try {
            await axios.put(
                `/formations/${state.currentFormation}/teachings/${modifications.teachingProcessId}/elements/${modifications.teachingProcessElementId}`,
                {
                    formationId: state.currentFormation,
                    ...modifications,
                },
            );

            commit("modifyTeachingProcessElement", modifications);
        } catch (e) {
            console.warn(e);
        }
    },

    async getOperationalObjectives({ commit }, formationId) {
        try {
            const result = await axios.get("/formations/" + formationId + "/operational");
            const operationals = result.data;

            commit("setOperationalList", operationals);
        } catch (error) {
            console.error(error);
        }
    },

    async getSpecificObjectives({ commit }, formationId) {
        try {
            const result = await axios.get("/formations/" + formationId + "/specific");
            const specifics = result.data;

            commit("setSpecificList", specifics);
        } catch (error) {
            console.error(error);
        }
    },

    async switchOrder({ dispatch }, { type, formationId, parentId, oldIndex, newIndex }) {
        console.log(parentId)
        try {
            await axios.post("/formations/" + formationId + "/order/", {
                formationId,
                type,
                parentId,
                oldIndex,
                newIndex,
            });

            dispatch("setCurrentFormation", formationId);
        } catch (error) {
            console.error(error);
        }
    }
};
