import Vue from "vue";

// String filters
function capitalize(value) {
    if (!value) return "";
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
}
Vue.filter("capitalize", capitalize);

// Date filters
function durationToHuman(duration) {
    if (!duration || !Number.isInteger(duration)) {
        return "0h00";
    }

    const hours = Math.floor(duration / 60);
    let minutes = (duration % 60).toString(10);

    if (minutes.length === 1) {
        minutes = "0" + minutes;
    }

    return hours + "h" + minutes;
}
Vue.filter("durationToHuman", durationToHuman);

function durationToHumanLong(duration) {
    if (!duration || !Number.isInteger(duration)) {
        return "0 minute";
    }

    const hours = Math.floor(duration / 60);
    const minutes = (duration % 60).toString(10);

    const hourText = hours > 1 ? "heures" : "heure";
    const minuteText = minutes > 1 ? "minutes" : "minute";

    if (hours > 0) {
        return hours + " " + hourText + " " + minutes + " " + minuteText;
    } else {
        return minutes + " " + minuteText;
    }
}
Vue.filter("durationToHumanLong", durationToHumanLong);

export { capitalize, durationToHuman, durationToHumanLong };
