export default {
    setJwtToken(state, token) {
        state.jwtToken = token;
    },

    setUserData(state, data) {
        state.user = data;
    },

    clearToken(state) {
        state.jwtToken = "";
        state.user = null;
    },

    setRefreshTask(state, refreshTask) {
        if (!refreshTask) {
            clearInterval(state.refreshTask);
        }

        state.refreshTask = refreshTask;
    },

    setCoworkers(state, coworkers) {
        state.coworkers = coworkers;
    }
};
