import Vue from "vue";

export default {
    // Synopsis
    setCurrentSynopsis(state, synopsisId) {
        state.currentSynopsis = synopsisId;
    },

    setSynopsisList(state, synopsis) {
        state.synopsis = synopsis;
    },

    addSynopsis(state, synopsis) {
        state.synopsis.push(synopsis);
    },

    modifySynopsis(state, modifications) {
        const synopsisIndex = state.synopsis.findIndex((obj) => obj.id === state.currentSynopsis);
        Vue.set(state.synopsis, synopsisIndex, { ...state.synopsis[synopsisIndex], ...modifications });
    },

    removeSynopsis(state, id) {
        const synopsisIndex = state.synopsis.findIndex((obj) => obj.id === id);
        state.synopsis.splice(synopsisIndex, 1);
    },

    // SynopsisBlock
    setSynopsisBlockList(state, blocks) {
        state.synopsisBlocks = blocks;
    },

    addSynopsisBlock(state, block) {
        state.synopsisBlocks.push(block);
    },

    modifySynopsisBlock(state, modifications) {
        const synopsisBlockIndex = state.synopsisBlocks.findIndex((obj) => obj.id === modifications.id);
        Vue.set(state.synopsisBlocks, synopsisBlockIndex, { ...state.synopsisBlocks[synopsisBlockIndex], ...modifications });
    },

    removeSynopsisBlock(state, id) {
        const synopsisBlockIndex = state.synopsisBlocks.findIndex((obj) => obj.id === id);
        const blockOrder = state.synopsisBlocks[synopsisBlockIndex].order;
        state.synopsisBlocks.splice(synopsisBlockIndex, 1);

        for (let index = blockOrder; index < state.synopsisBlocks.length; index++) {
            const newBlockOrder = state.synopsisBlocks[index].order - 1;
            Vue.set(state.synopsisBlocks, index, { ...state.synopsisBlocks[index], order: newBlockOrder });
        }
    },

    // SynopsisElement
    setSynopsisElementList(state, elements) {
        state.synopsisElements = elements;
    },

    addSynopsisElement(state, element) {
        state.synopsisElements.push(element);
    },

    modifySynopsisElement(state, modifications) {
        const synopsisElementIndex = state.synopsisElements.findIndex((obj) => obj.id === modifications.id);
        Vue.set(state.synopsisElements, synopsisElementIndex, { ...state.synopsisElements[synopsisElementIndex], ...modifications });
    },

    removeSynopsisElement(state, id) {
        const synopsisElementIndex = state.synopsisElements.findIndex((obj) => obj.id === id);
        const elementOrder = state.synopsisElements[synopsisElementIndex].order;
        state.synopsisElements.splice(synopsisElementIndex, 1);

        for (let index = elementOrder; index < state.synopsisElements.length; index++) {
            const newElementOrder = state.synopsisElements[index].order - 1;
            Vue.set(state.synopsisElements, index, { ...state.synopsisElements[index], order: newElementOrder });
        }
    },
};
