export default {
    currentFormation: null,
    formations: [],

    educationalObjectives: [],
    operationalObjectives: [],
    specificObjectives: [],

    currentVirtualClass: null,
    virtualClasses: [],
    virtualClassElements: [],

    currentTeachingProcess: null,
    teachingProcesses: [],
    teachingProcessElements: [],
};
