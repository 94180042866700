export default [
    // Formations
    {
        path: "/formations",
        name: "FormationsList",
        component: () => import("@/views/designer/FormationsList"),
        meta: {
            requiresAuth: true,

            title: "Mes formations"
        },
    },
    {
        path: "/formations/:id/scoping/",
        name: "FormationScoping",
        component: () => import("@/views/designer/panels/Scoping"),
        meta: {
            requiresAuth: true,
            inside: "formation",

            title: "Cadrage",
            color: "blue darken-1",
            download: "scoping",
            next: "FormationObjective",
        },
    },
    {
        path: "/formations/:id/objectives/",
        name: "FormationObjective",
        component: () => import("@/views/designer/panels/Objectives"),
        meta: {
            requiresAuth: true,
            inside: "formation",

            title: "Objectifs",
            color: "purple lighten-1",
            next: "FormationScript",
        },
    },
    {
        path: "/formations/:id/script/",
        name: "FormationScript",
        component: () => import("@/views/designer/panels/Script"),
        meta: {
            requiresAuth: true,
            inside: "formation",

            title: "Script",
            color: "pink",
            next: "FormationTutoring",
            download: ["script", "referential"],
        },
    },
    {
        path: "/formations/:id/tutoring/",
        name: "FormationTutoring",
        component: () => import("@/views/designer/panels/Tutoring"),
        meta: {
            requiresAuth: true,
            inside: "formation",

            title: "Accompagnement",
            color: "pinkred",
            next: "FormationVirtual",
            download: "tutoring",
        },
    },
    {
        path: "/formations/:id/virtual/:virtualId?",
        name: "FormationVirtual",
        component: () => import("@/views/designer/panels/VirtualClasses"),
        meta: {
            requiresAuth: true,
            inside: "formation",

            title: "Classes virtuelles",
            color: "orange",
        },
    },
    {
        path: "/formations/:id/teaching/:teachingId?",
        name: "FormationTeaching",
        component: () => import("@/views/designer/panels/TeachingProcesses"),
        meta: {
            requiresAuth: true,
            inside: "formation",

            title: "Déroulé séquence",
            color: "orange",
        },
    },
    {
        path: "/formations/:id/instant/",
        name: "FormationInstant",
        component: () => import("@/views/designer/panels/Instant.vue"),
        meta: {
            requiresAuth: true,
            inside: "formation",

            title: "Synoptique",
        },
    },

    // Synopsis
    {
        path: "/synopsis",
        name: "SynopsisList",
        component: () => import("@/views/designer/SynopsisList"),
        meta: {
            requiresAuth: true,

            title: "Mes ressources",
        },
    },
    {
        path: "/synopsis/:id/",
        name: "SynopsisDesigner",
        component: () => import("@/views/designer/SynopsisDesigner"),
        meta: {
            requiresAuth: true,
            inside: "synopsis",

            title: "Ma ressource",
            download: "synopsis",
        },
    },

    {
        path: "/settings",
        name: "Settings",
        // component: () => import("@/views/baba"),
        meta: {
            requiresAuth: true,

            title: "Paramétrages",
        },
    },
];
