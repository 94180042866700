<template>
    <v-navigation-drawer app :class="[mini ? 'black' : 'white']" :mini-variant="mini" floating fixed class="elevation-3" height="92%" permanent>
        <v-list nav dense>
            <v-list-item class="text-center">
                <v-list-item-content >
                    <v-list-item-avatar>
                        <img :src="avatar_link" alt="Photo de profil">
                    </v-list-item-avatar>
                    <v-list-item-title class="font-weight-bold text-h5" v-show="!mini">{{ user.firstName }} {{ user.lastName }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider class="mt-16 mb-5"></v-divider>

            <v-list-item
                v-for="link in navigationLinks"
                :key="link.name"
                :to="link.url"
                class="nav-btn"
                :ripple="false"
                :id="link.name + '_nav_button'"
                :class="{ 'ml-7': link.inside && !mini }"
                exact
            >
                <v-list-item-icon :class="{ 'mr-4': link.inside }"><v-icon v-text="link.icon" :color="mini ? 'white' : 'black'"></v-icon></v-list-item-icon>
                <v-list-item-title v-text="link.text" class="text-subtitle-1"></v-list-item-title>
            </v-list-item>
        </v-list>
        <template v-slot:append>
            <v-list-item
                :to="{ name: 'Subscription' }"
                class="nav-btn"
                :ripple="false"
                id="subscription_nav_button"
                exact
            >
                <v-list-item-icon><v-icon color="black">fas fa-coins</v-icon></v-list-item-icon>
                <v-list-item-title class="text-subtitle-1">Abonnement</v-list-item-title>
            </v-list-item>
            v{{ versionApp }}
        </template>
    </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "NavigationDrawer",

    data: () => ({
        versionApp: process.env.VUE_APP_VERSION,
        baseAvatarLink: "https://ui-avatars.com/api/?background=0D8ABC&color=fff&size=128&rounded=true&name=",
        mini: false,
    }),

    computed: {
        ...mapState("formations", ["currentFormation", "educationalObjectives", "specificObjectives"]),
        ...mapState("settings", ["user"]),

        avatar_link() {
            return `${this.baseAvatarLink}${encodeURI(this.user.firstName)}+${encodeURI(this.user.lastName)}`;
        },
        
        navigationLinks() {
            const links = [
                {
                    name: "home",
                    url: { name: "Home" },
                    text: "Méthodologie",
                    icon: "fas fa-home",
                },
                {
                    name: "profile",
                    url: { name: "Profile" },
                    text: "Profil",
                    icon: "fas fa-user-circle",
                },
                { name: "formations", url: { name: "FormationsList" }, text: "Mes formations", icon: "fas fa-folder-plus" },
                {
                    name: "scoping",
                    url: { name: "FormationScoping", params: { id: this.currentFormation } },
                    text: "Cadrage",
                    icon: "fas fa-object-group",
                    inside: true,
                },
                {
                    name: "instant",
                    url: { name: "FormationInstant", params: { id: this.currentFormation } },
                    text: "Synoptique",
                    icon: "fas fa-map",
                    inside: true,
                },
                {
                    name: "objectives",
                    url: { name: "FormationObjective", params: { id: this.currentFormation } },
                    text: "Objectifs",
                    icon: "fas fa-bullseye",
                    inside: true,
                },
                {
                    name: "script",
                    url: { name: "FormationScript", params: { id: this.currentFormation } },
                    text: "Script",
                    icon: "fas fa-cogs",
                    inside: true,
                },
                {
                    name: "tutoring",
                    url: { name: "FormationTutoring", params: { id: this.currentFormation } },
                    text: "Accompagnement",
                    icon: "fas fa-hands-helping",
                    inside: true,
                },
                {
                    name: "virtual",
                    url: { name: "FormationVirtual", params: { id: this.currentFormation } },
                    text: "Classes virtuelles",
                    icon: "fas fa-laptop",
                    inside: true,
                },
                {
                    name: "teaching",
                    url: { name: "FormationTeaching", params: { id: this.currentFormation } },
                    text: "Déroulé séquence",
                    icon: "fas fa-chalkboard-teacher",
                    inside: true,
                },
                {
                    name: "synopsis",
                    url: { name: "SynopsisList" },
                    text: "Mes ressources",
                    icon: "fas fa-file-alt",
                },
            ];

            if(!this.currentFormation) {
                links.splice(3, 7);
            }

            if (this.currentFormation && this.specificObjectives.length === 0) {
                links.splice(6, 4);
                links.splice(4, 1)
            }

            return links;
        },
    },
};
</script>

<style scoped>

.nav-btn:hover {
    background-color: var(--v-grey-base);
}

.v-list-item--active {
    background-color: var(--v-grey-base);
}

.v-list-item--active .v-list-item__title {
    font-weight: 700 !important;
}

.v-list-item__title {
    line-height: 2rem !important;
}

.v-list-item__icon > .v-icon {
    color: var(--v-grey-darken1) !important;
}
.v-list-item--active > .v-list-item__icon > .v-icon {
    color: black !important;
}

.v-navigation-drawer__border {
    display: none;
}
</style>