import Vue from "vue";
import VueRouter from "vue-router";

import globalRoutes from "./routes/global";
import designerRoutes from "./routes/designer";

import store from "@/store";

Vue.use(VueRouter);

const routes = [...globalRoutes, ...designerRoutes];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (!to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters["settings/getIsConnected"]) {
            if(to.query.redirect === "subscription") {
                return next({ name: "Subscription" });
            }
            return next({ name: "Home" });
        }
        return next();
    }

    if (!store.getters["settings/getIsConnected"]) {
        return next({ name: "Login" });
    }

    if (to.matched.some((record) => record.meta.inside)) {
        const inside = to.meta.inside;

        if (inside === "formation") {
            const formationId = to.params.id;
            const currentFormation = store.state.formations.currentFormation;

            if (formationId !== currentFormation) {
                store.dispatch("formations/setCurrentFormation", formationId).then(() => {
                    next();
                });
            } else {
                next();
            }
        } else if (inside === "synopsis") {
            const synopsisId = to.params.id;
            const currentSynopsis = store.state.synopsis.currentSynopsis;

            if (synopsisId !== currentSynopsis) {
                store.dispatch("synopsis/setCurrentSynopsis", synopsisId).then(() => {
                    next();
                });
            } else {
                next();
            }
        }

        return;
    }

    next();
});

router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title + " - " + process.env.VUE_APP_TITLE;
    });
});
export default router;
