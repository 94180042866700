export default {
    bloomList: [
        {
            name: "Connaissance",
            color: "#16E7CF",
            words: [
                "Arranger",
                "Associer",
                "Choisir",
                "Citer",
                "Collecter",
                "Copier",
                "Décrire",
                "Définir",
                "Détailler",
                "Dire",
                "Documenter",
                "Dupliquer",
                "Énoncer",
                "Enregistrer",
                "Énumérer",
                "Étiqueter",
                "Formuler",
                "Identifier",
                "Indiquer",
                "Lire",
                "Lister",
                "Localiser",
                "Mémoriser",
                "Montrer",
                "Nommer",
                "Ordonner",
                "Rappeler",
                "Rapporter",
                "Réciter",
                "Reconnaitre",
                "Reconstituer",
                "Recueillir",
                "Relater",
                "Relier",
                "Répéter",
                "Reproduire",
                "Retenir",
                "Retracer",
                "Sélectionner",
                "Situer",
                "Spécifier",
            ],
        },
        {
            name: "Compréhension",
            color: "#FBE232",
            words: [
                "Admettre",
                "Associer",
                "Citer",
                "Classer",
                "Communiquer",
                "Comparer",
                "Compléter",
                "Conseiller",
                "Construire",
                "Contrôler",
                "Convertir",
                "Déchiffrer",
                "Décrire",
                "Différencier",
                "Discuter",
                "Distinguer",
                "Élargir",
                "Estimer",
                "Établir",
                "Expliquer",
                "Exprimer",
                "Généraliser",
                "Illustrer",
                "Indiquer",
                "Interpréter",
                "Noter",
                "Nuancer",
                "Ordonner",
                "Paraphraser",
                "Préciser",
                "Prédire",
                "Prévoir",
                "Rapporter",
                "Reconnaître",
                "Reformuler",
                "Regrouper",
                "Relier",
                "Rendre compte",
                "Repérer",
                "Représenter",
                "Résumer",
                "Retracer",
                "Réviser",
                "Sélectionner",
                "Traduire",
            ],
        },
        {
            name: "Application",
            color: "#CA89D6",
            words: [
                "Administrer",
                "Affirmer",
                "Ajuster",
                "Animer",
                "Appliquer",
                "Assembler",
                "Calculer",
                "Capter",
                "Catégoriser",
                "Changer",
                "Circuler",
                "Combiner",
                "Compiler",
                "Compléter",
                "Conduire",
                "Convertir",
                "Corriger",
                "Décoder",
                "Décrire",
                "Démarrer",
                "Démontrer",
                "Dessiner",
                "Déterminer",
                "Différencier",
                "Diriger",
                "Discerner",
                "Diviser",
                "Échanger",
                "Écrire",
                "Effectuer",
                "Employer",
                "Établir",
                "Examiner",
                "Expérimenter",
                "Exploiter",
                "Extraire",
                "Faire un croquis",
                "Formuler",
                "Fournir",
                "Généraliser",
                "Gérer",
                "Illustrer",
                "Implanter",
                "Inclure",
                "Inférer",
                "Informer",
                "Instruire",
                "Interpréter",
                "Jouer",
                "Limiter",
                "Manipuler",
                "Maintenir",
                "Manier",
                "Mesurer",
                "Mettre en oeuvre",
                "Mettre en place",
                "Mettre en pratique",
                "Mettre en scène",
                "Modéliser",
                "Modifier",
                "Montrer",
                "Opérer",
                "Organiser",
                "Participer",
                "Planifier",
                "Positionner",
                "Pratiquer",
                "Produire",
                "Proportionner",
                "Raconter",
                "Réaliser",
                "Rechercher",
                "Rédiger",
                "Relier",
                "Représenter",
                "Résoudre",
                "Séparer",
                "Simuler",
                "Sortir",
                "Traiter",
                "Transférer",
                "Trouver",
                "Utiliser",
                "Vérifier",
                "Voler",
            ],
        },
        {
            name: "Analyse",
            color: "#48A64C",
            words: [
                "Analyser",
                "Appréhender",
                "Arranger",
                "Associer",
                "Caractériser",
                "Catégoriser",
                "Cerner",
                "Cibler",
                "Comparer",
                "Concentrer",
                "Contraster",
                "Corréler",
                "Critiquer",
                "Décomposer",
                "Déconstruire",
                "Décortiquer",
                "Découper",
                "Déduire",
                "Délimiter",
                "Détecter",
                "Différencier",
                "Discriminer",
                "Disséquer",
                "Disserter",
                "Distinguer",
                "Diviser",
                "Esquisser",
                "Étudier",
                "Examiner",
                "Expliquer",
                "Explorer",
                "Faire ressortir",
                "Interpréter",
                "Limiter",
                "Mettre en priorité",
                "Mettre en relation",
                "Morceler",
                "Opposer",
                "Organiser",
                "Questionner",
                "Rattacher",
                "Recourir",
                "Relier",
                "Répartir",
                "Schématiser",
                "Sélectionner",
                "Séparer",
                "Signaler",
                "Subdiviser",
                "Tester",
                "Tester une hypothèse",
                "Tracer",
            ],
        },
        {
            name: "Evaluation",
            color: "#FB8C00",
            words: [
                "Anticiper",
                "Apprécier",
                "Argumenter",
                "Chiffrer",
                "Choisir",
                "Collaborer",
                "Compiler",
                "Composer",
                "Concevoir",
                "Conclure",
                "Construire",
                "Critiquer",
                "Décrire",
                "Défendre",
                "Déterminer",
                "Estimer",
                "Évaluer",
                "Exprimer",
                "Faciliter",
                "Formuler",
                "Généraliser",
                "Imaginer",
                "Intégrer",
                "Intervenir",
                "Inventer",
                "Juger",
                "Justifier",
                "Modifier",
                "Négocier",
                "Planifier",
                "Préparer",
                "Produire",
                "Proposer",
                "Réécrire",
                "Renforcer",
                "Réordonner",
                "Réorganiser",
                "Respecter",
                "Soutenir",
                "Structurer",
                "Substituer",
                "Suggérer",
                "Supporter",
                "Valider",
            ],
        },
        {
            name: "Création",
            color: "#5BA5FB",
            words: [
                "Adapter",
                "Agencer",
                "Arranger",
                "Assembler",
                "Chiffrer",
                "Classer",
                "Combiner",
                "Comparer",
                "Composer",
                "Concevoir",
                "Connecter",
                "Construire",
                "Convaincre",
                "Créer",
                "Critiquer",
                "Decider",
                "Déduire",
                "Développer",
                "Discriminer",
                "Distinguer",
                "Estimer",
                "Expliquer",
                "Exploiter",
                "Explorer",
                "Exposer",
                "Fabriquer",
                "Fonder",
                "Incorporer",
                "Intégrer",
                "Mesurer",
                "Modéliser",
                "Noter",
                "Organiser",
                "Persuader",
                "Prédire",
                "Proposer",
                "Rattacher",
                "Recommander",
                "Reformuler",
                "Résumer",
                "Réviser",
                "Structurer",
                "Supporter",
                "Synthétiser",
            ],
        },
    ],

    approachList: ["Transmissive", "Active", "Déductive", "Inductive", "Transmissive et active", "Collaborative", "Coopérative"],

    methodList: [
        "Jeu",
        "Wiki",
        "E-book",
        "Atelier",
        "Mind-map",
        "Feedback",
        "Évaluation",
        "Restitution",
        "Étude de cas",
        "Simulation",
        "Jeu de rôle",
        "Notes de cours",
        "Cours en ligne",
        "Recherche web",
        "Démonstration",
        "Vidéo en ligne",
        "Échange de poste",
        "Atelier en ligne",
        "Classe virtuelle",
        "Réalité virtuelle",
        "Réalité augmentée",
        "Evaluation formative",
        "Documents entreprises",
        "Production de livrable",
        "Apprentissage collaboratif",
    ],

    tutoringMethodList: [
        "En présence",
        "Classe virtuelle",
        "Asynchrone, mail...",
        "Forum ou autre message écrit",
        "Rétroactions sur les productions",
        "Synchrone, messagerie, téléphone...",
    ],

    learnerApprochList: [
        "En présence",
        "A distance"
    ],

    toolList: [
        "Aucun",
        "Mind Map",
        "Tableur",
        "Prise de notes",
        "Tableau blanc",
        "Outil RA ou RV",
        "Traitement vidéo",
        "Traitement audio",
        "Traitement de texte",
        "Outil de production",
        "Au choix de l'apprenant",
        "Outil de classe virtuelle",
    ],

    ressourceList: [
        "Aucune",
        "Formateur",
        "Page web dédiée",
        "Support de cours",
        "Documents entreprise",
        "Logiciel entreprise",
        "Archives entreprise",
        "Personne ressource",
        "Résultat de recherche",
        "Ressources mises à disposition",
    ],

    frequenceList: [
        "Avant le début de la formation",
        "Au début de la formation",
        "Avant le démarrage du module",
        "En début de module",
        "Pendant la durée du module",
        "Au début de la séquence",
        "Pendant la séquence",
        "Avant l'activité",
        "Pendant l'activité",
        "Après l'activité",
        "Avant le début d'un exercice asynchrone",
        "Lors d'un exercice asynchrone",
        "Après un exercice asynchrone",
        "Lors d'un travail en entreprise",
        "Lors d'une intersession",
    ],

    interventionGoalsList: [
        "Informer sur le dispositif et orienter l’apprenant",
        "Présenter les méthodologies appropriées",
        "Apporter des réponses ou les susciter",
        "Personnaliser le soutien à l’apprentissage",
        "Aider à maîtriser l’environnement d’apprentissage",
        "Faire prendre conscience de ses préférences cognitives",
        "Annoncer clairement les critères d’évaluation",
        "Faire émerger les objectifs personnels de l’apprenant",
        "Accompagner les processus d’autonomie",
        "Proposer des activités signifiantes",
        "Lutter contre l’abandon",
        "Encourager l’utilisation des outils",
        "Faire identifier les motivations intrinsèque",
        "Encourager et féliciter",
        "Initier la construction d’un sentiment d’appartenance",
        "Réguler la dynamique de groupe",
        "Faciliter la collaboration des apprenants",
        "Rompre l’isolement de l’apprenant",
        "Susciter l’entraide technique entre apprenants",
        "Faciliter la prise de conscience des états affectifs / tâches",
        "Produire des rétroactions à portée formative",
        "Inciter l’apprenant à faire le point sur ses stratégies cognitives",
        "Faciliter la planification de l’apprentissage",
        "Susciter l’expression critique sur le dispositif",
        "Faire prendre conscience de ses habiletés à collaborer",
        "Susciter la prise de distance réflexive sur les usages des outils",
        "Faire identifier les motivations intrinsèques",
        "Aider à s’auto-évaluer",
    ],

    interventionGoals: [
        {
            name: "Informer sur le dispositif et orienter l’apprenant",
            answer: [
                "Comment accéder au LMS",
                "Comment démarrer le parcours",
                "Connaître la durée du module",
                "Connaître les délais de production",
                "Savoir qui appeler en cas de blocage",
            ],
            tutoringSupportPlan: "Cognitif",
            tutoringFunction: "Accueil et orientation",
        },
        {
            name: "Présenter les méthodologies appropriées",
            answer: ["Ordre de suivi des modules", "Guidage vers les ressources", "Orientation des recherches"],
            tutoringSupportPlan: "Cognitif",
            tutoringFunction: "Organisationnelle",
        },
        {
            name: "Apporter des réponses ou les susciter",
            answer: [
                "Aux questions posées",
                "Reformulation des savoirs qui posent questions dans les ressources proposées",
                "Orientation des recherches",
            ],
            tutoringSupportPlan: "Cognitif",
            tutoringFunction: "Pédagogique",
        },
        {
            name: "Personnaliser le soutien à l’apprentissage",
            answer: ["Relance régulière", "Renforcement", "Parcours adapté aux capacités de l’apprenant"],
            tutoringSupportPlan: "Cognitif",
            tutoringFunction: "Socio-affective, Motivationnelle",
        },
        {
            name: "Aider à maîtriser l’environnement d’apprentissage",
            answer: ["Didacticiel pour intégrer le parcours", "Didacticiel pour intégrer la classe virtuelle"],
            tutoringSupportPlan: "Cognitif",
            tutoringFunction: "Technique",
        },
        {
            name: "Faire prendre conscience de ses préférences cognitives",
            answer: "Rétroaction sur les productions",
            tutoringSupportPlan: "Cognitif",
            tutoringFunction: "Métacognitif",
        },
        {
            name: "Annoncer clairement les critères d’évaluation",
            answer: "Évaluations formatives tout au long du parcours",
            tutoringSupportPlan: "Cognitif",
            tutoringFunction: "Évaluative",
        },
        {
            name: "Faire émerger les objectifs personnels de l’apprenant",
            answer: ["Brise glace", "Questionnaire", "Échange avec les pairs", "Entretien personnel avec l’apprenant"],
            tutoringSupportPlan: "Motivationnel",
            tutoringFunction: "Accueil et orientation",
        },
        {
            name: "Accompagner les processus d’autonomie",
            answer: ["Aider à surmonter la peur", "Aider à identifier les réponses", "Aider à formuler son problème"],
            tutoringSupportPlan: "Motivationnel",
            tutoringFunction: "Organisationnelle",
        },
        {
            name: "Proposer des activités signifiantes",
            answer: ["Pratiquer", "Analyser", "Généraliser", "Transférer"],
            tutoringSupportPlan: "Motivationnel",
            tutoringFunction: "Pédagogique",
        },
        {
            name: "Lutter contre l’abandon",
            answer: [
                "Évaluer les compétences",
                "Questionnaire sur les motivations",
                "Entretien sur les difficultés personnelles",
                "Tuteur en position d’écoute active",
            ],
            tutoringSupportPlan: "Motivationnel",
            tutoringFunction: "Socio-affective, Motivationnelle",
        },
        {
            name: "Encourager l’utilisation des outils",
            answer: [
                "Faire analyser les apports des outils",
                "Faire échanger sur les outils",
                "Proposer de décrire les potentialités de l’outil",
            ],
            tutoringSupportPlan: "Motivationnel",
            tutoringFunction: "Technique",
        },
        {
            name: "Faire identifier les motivations intrinsèque",
            answer: "Rétroaction sur les productions",
            tutoringSupportPlan: "Motivationnel",
            tutoringFunction: "Métacognitive",
        },
        {
            name: "Encourager et féliciter",
            answer: ["Prodiguer des encouragements", "Féliciter l’apprenant de façon argumentée"],
            tutoringSupportPlan: "Motivationnel",
            tutoringFunction: "Évaluative",
        },
        {
            name: "Initier la construction d’un sentiment d’appartenance",
            answer: ["Brise glace en classe virtuelle", "Portrait chinois", "Tour de table", "Pecha Kucha"],
            tutoringSupportPlan: "Socio-affectif",
            tutoringFunction: "Accueil et orientation",
        },
        {
            name: "Réguler la dynamique de groupe",
            answer: ["Constitution de groupes", "Faire endosser des rôles", "Création d’un sociogramme"],
            tutoringSupportPlan: "Socio-affectif",
            tutoringFunction: "Organisationnelle",
        },
        {
            name: "Faciliter la collaboration des apprenants",
            answer: [
                "Faire prendre conscience des pièges de l’illusion groupale",
                "Identifier les rôles",
                "Définir les modalités de communication",
            ],
            tutoringSupportPlan: "Socio-affectif",
            tutoringFunction: "Pédagogique",
        },
        {
            name: "Rompre l’isolement de l’apprenant",
            answer: ["Rappeler la disponibilité du tuteur", "Faire des rappels sur les activités", "Rappeler les échéances"],
            tutoringSupportPlan: "Socio-affectif",
            tutoringFunction: "Socio-affective, Motivationnelle",
        },
        {
            name: "Susciter l’entraide technique entre apprenants",
            answer: ["Susciter le soutien technique", "Susciter l’entraide entre pairs"],
            tutoringSupportPlan: "Socio-affectif",
            tutoringFunction: "Technique",
        },
        {
            name: "Faciliter la prise de conscience des états affectifs / tâches",
            answer: ["Aider à la prise de conscience par l’échange", "Faire témoigner sur les tâches d’apprentissage"],
            tutoringSupportPlan: "Socio-affectif",
            tutoringFunction: "Métacognitive",
        },
        {
            name: "Produire des rétroactions à portée formative",
            answer: "Évaluations formatives tout au long du parcours",
            tutoringSupportPlan: "Socio-affectif",
            tutoringFunction: "Évaluative",
        },
        {
            name: "Inciter l’apprenant à faire le point sur ses stratégies cognitives",
            answer: ["Échelle de Likert", "Construire un répertoire des stratégies cognitives", "Témoignage du tuteur"],
            tutoringSupportPlan: "Métacognitif",
            tutoringFunction: "Accueil et orientation",
        },
        {
            name: "Faciliter la planification de l’apprentissage",
            answer: ["Aider à planifier les apprentissages", "Organiser un plan des apprentissages", "Ordonner une succession d’actions"],
            tutoringSupportPlan: "Métacognitif",
            tutoringFunction: "Organisationnelle",
        },
        {
            name: "Susciter l’expression critique sur le dispositif",
            answer: ["Ouvrir un espace de communication", "Faire s’exprimer les apprenants", "Hiérarchiser les difficultés rencontrées"],
            tutoringSupportPlan: "Métacognitif",
            tutoringFunction: "Pédagogique",
        },
        {
            name: "Faire prendre conscience de ses habiletés à collaborer",
            answer: ["Favoriser la collaboration", "Questionner les apprenants", "Mettre en place un forum", "Tenue d’un carnet de bord"],
            tutoringSupportPlan: "Métacognitif",
            tutoringFunction: "Socio-affective, Motivationnelle",
        },
        {
            name: "Susciter la prise de distance réflexive sur les usages des outils",
            answer: [
                "Faire réfléchir aux usages des outils",
                "Aider à décrire la maîtrise fonctionnelle",
                "Faire décrire les usages préférentiels",
            ],
            tutoringSupportPlan: "Métacognitif",
            tutoringFunction: "Technique",
        },
        {
            name: "Faire identifier les motivations intrinsèques",
            answer: [
                "Aider à l’apprenant à s’identifier",
                "Lui faire découvrir ses manières d’apprendre",
                "Lui faire prendre conscience de sa motivation",
                "L’aider à analyser ses stratégies cognitives",
            ],
            tutoringSupportPlan: "Métacognitif",
            tutoringFunction: "Métacognitive",
        },
        {
            name: "Aider à s’auto-évaluer",
            answer: ["Fournir des exemples de critères", "Apporter un soutien méthodologique", "Inviter à revisiter son journal de bord"],
            tutoringSupportPlan: "Métacognitif",
            tutoringFunction: "Socio-affective",
        },
    ],

    interestList: ["Expliquer/informer", "Stimuler/motiver", "Renforcer la mémorisation", "Générer de l'implication", "Aider à comprendre", "Évaluer les acquis", "Généraliser"],

    objectList: [
        "Introduction au cours",
        "Présentation du cours",
        "Organisation du module",
        "présenter les Objectifs",
        "Évaluation formative",
        "Évaluation",
        "Question",
        "Feedback",
        "Énoncé",
        "Exposé",
        "Synthèse",
        "Corrigé",
        "Conclusion",
    ],

    actorList: [
        "Tuteur technique",
        "Tuteur administratif",
        "Tuteur cours",
        "Tuteur pair",
        "Tuteur entreprise",
        "Mentor",
        "Apprenant",
        "Coach",
        "Formateur",
    ],

    alerts: [],

    formationStateList: {
        ongoing: {
            name: "En cours",
            color: "secondary"
        },
        todo: {
            name: "A faire",
            color: "grey darken-1"
        },
        validated: {
            name: "Validée",
            color: "success"
        },
        pending: {
            name: "En attente",
            color: "primary"
        },
        canceled: {
            name: "Annulée",
            color: "error"
        },
    },

};
