const axios = require("axios").default;

const BASE_URL = process.env.VUE_APP_API_SERVER_URL;

const HTTP = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-type": "application/json",
    },
});

HTTP.setHeaders = (headers) => {
    Object.assign(HTTP.defaults.headers, headers);
}

export default HTTP;