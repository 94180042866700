import Vue from "vue";
import Vuex from "vuex";

import formations from "./modules/formations";
import synopsis from "./modules/synopsis";
import settings from "./modules/settings";
import global from "./modules/global";

import createPersistedState from "vuex-persistedstate";

import axios from "axios";
import { default as HTTP } from "@/utils/http";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        formations: formations,
        synopsis: synopsis,

        settings: settings,
        global: global,
    },
    plugins: [
        createPersistedState({
            paths: ["settings"],
            storage: window.localStorage,
        }),
    ],
    strict: process.env.NODE_ENV !== "production",
});

if (store.state.settings.user != null) {
    if (store.state.settings.user.exp * 1000 < Date.now()) {
        store.dispatch("settings/clearToken");
    } else {
        axios.defaults.headers.common = { Authorization: "Bearer " + store.state.settings.jwtToken };

        HTTP.setHeaders({
            common: {
                Authorization: `Bearer ${store.state.settings.jwtToken}`,
            }
        });

        store.dispatch("settings/setAutoRefresh");
    }
}

export default store;