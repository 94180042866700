export default {
    getFormationById: (state) => (formationId) => {
        return state.formations.find((formation) => formation.id === formationId);
    },

    // Getters Last Id
    getLastEducationalObjectiveId(state) {
        return state.educationalObjectives[state.educationalObjectives.length - 1].id;
    },

    getLastOperationalObjectiveId(state) {
        return state.operationalObjectives[state.operationalObjectives.length - 1].id;
    },

    getLastSpecificObjectiveId(state) {
        return state.specificObjectives[state.specificObjectives.length - 1].id;
    },

    // Getters New Id
    getNewEducationalObjectiveId(state) {
        return state.educationalObjectives[state.educationalObjectives.length - 1].id + 1;
    },

    getNewOperationalObjectiveId(state) {
        return state.operationalObjectives[state.operationalObjectives.length - 1].id + 1;
    },

    getNewSpecificObjectiveId(state) {
        return state.specificObjectives[state.specificObjectives.length - 1].id + 1;
    },

    // Getters New Order
    getNewEducationalObjectiveOrder(state) {
        return state.educationalObjectives.length;
    },

    getNewOperationalObjectiveOrder: (state) => (parentId) => {
        return state.operationalObjectives.filter((obj) => obj.educationalObjectiveId === parentId).length;
    },

    getNewSpecificObjectiveOrder: (state) => (parentId) => {
        return state.specificObjectives.filter((obj) => obj.operationalObjectiveId === parentId).length;
    },

    getNewVirtualClassElementOrder: (state) => (parentId) => {
        return state.virtualClassElements.filter((obj) => obj.virtualClassId === parentId).length;
    },

    getNewTeachingProcessElementOrder: (state) => (parentId) => {
        return state.teachingProcessElements.filter((obj) => obj.teachingProcessId === parentId).length;
    },

    // Getters by Id
    getEducationalObjectiveById: (state) => (id) => {
        return state.educationalObjectives.find((obj) => obj.id === id);
    },

    getOperationalObjectiveById: (state) => (id) => {
        return state.operationalObjectives.find((obj) => obj.id === id);
    },

    getSpecificObjectiveById: (state) => (id) => {
        return state.specificObjectives.find((obj) => obj.id === id);
    },

    // Getters by Parent Id
    getOperationalObjectivesByParentId: (state) => (id) => {
        return state.operationalObjectives.filter((obj) => obj.educationalObjectiveId === id);
    },
    getSpecificObjectivesByParentId: (state) => (id) => {
        return state.specificObjectives.filter((obj) => obj.operationalObjectiveId === id);
    },

    // Virtual class
    getVirtualClassByFormationId: (state) => (formationId) => {
        return state.virtualClasses.find((classObject) => classObject.formationId === formationId);
    },

    getVirtualClassById: (state) => (classId) => {
        return state.virtualClasses.find((classObject) => classObject.id === classId);
    },

    getVirtualClassElementByParentId: (state) => (virtualClassId) => {
        return state.virtualClassElements.find((virtualClassElement) => virtualClassElement.virtualClassId === virtualClassId);
    },

    getVirtualClassElementById: (state) => (virtualClassElementId) => {
        return state.virtualClassElements.find((virtualClassElement) => virtualClassElement.id === virtualClassElementId);
    },

    // Teaching process
    getTeachingProcessByFormationId: (state) => (formationId) => {
        return state.teachingProcesses.find((teachingObject) => teachingObject.formationId === formationId);
    },

    getTeachingProcessById: (state) => (teachingId) => {
        return state.teachingProcesses.find((teachingObject) => teachingObject.id === teachingId);
    },

    getTeachingProcessElementByParentId: (state) => (teachingProcessId) => {
        return state.teachingProcessElements.find((teachingProcessElement) => teachingProcessElement.teachingProcessId === teachingProcessId);
    },

    getTeachingProcessElementById: (state) => (teachingProcessElementId) => {
        return state.teachingProcessElements.find((teachingProcessElement) => teachingProcessElement.id === teachingProcessElementId);
    },
};
