export default {
    getSynopsisById: (state) => (synopsisId) => {
        return state.synopsis.find((synopsis) => synopsis.id === synopsisId);
    },

    getNewSynopsisBlockOrder(state) {
        return state.synopsisBlocks.length;
    },

    getNewSynopsisElementOrder: (state) => (parentId) => {
        return state.synopsisElements.filter((obj) => obj.synopsisBlockId === parentId).length;
    },
};
