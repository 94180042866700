const axios = require("axios");
axios.defaults.baseURL = process.env.VUE_APP_API_SERVER_URL;

export default {
    // Synopsis

    async initStore({ commit }) {
        try {
            const result = await axios.get("/synopsis");
            commit("setSynopsisList", result.data);
        } catch (e) {
            console.warn(e);
        }
    },

    async getSynopsis({ commit }) {
        let synopsis = [];

        try {
            const result = await axios.get("/synopsis");
            synopsis = result.data;
        } catch (e) {
            console.warn(e);
        }

        commit("setSynopsisList", synopsis);
    },

    async addSynopsis({ commit, rootState, dispatch }, title) {
        try {
            const result = await axios.post("/synopsis/", {
                title: title,
                userId: rootState.settings.user.id,
            });

            commit("addSynopsis", {
                title: title,
                id: result.data,
                userId: rootState.settings.user.id,
            });

            await dispatch("addSynopsisBlock", { synopsisId: result.data });

            return result.data;
        } catch (e) {
            console.warn(e);
        }
    },

    async modifySynopsis({ commit, state }, modifications) {
        try {
            const result = await axios.put("/synopsis/" + state.currentSynopsis, {
                synopsisId: state.currentSynopsis,
                ...modifications,
            });

            commit("modifySynopsis", modifications);
        } catch (e) {
            console.warn(e);
        }
    },

    async removeSynopsis({ commit }, id) {
        try {
            await axios.delete("/synopsis/" + id);

            commit("removeSynopsis", id);
        } catch (e) {
            console.warn(e);
        }
    },

    async setCurrentSynopsis({ commit, dispatch, state }, synopsisId) {
        if (state.synopsis.length === 0) {
            await dispatch("getSynopsis");
        }
        commit("formations/setCurrentFormation", null, { root: true });
        commit("setCurrentSynopsis", synopsisId);

        try {
            let result = await axios.get("/synopsis/" + synopsisId + "/block");
            const synopsisBlock = result.data;

            result = await axios.get("/synopsis/" + synopsisId + "/element");
            const synopsisElement = result.data;

            commit("setSynopsisBlockList", synopsisBlock);
            commit("setSynopsisElementList", synopsisElement);
        } catch (e) {
            console.warn(e);
        }
    },

    // SynopsisBlock

    async addSynopsisBlock({ commit, getters, state }, block) {
        try {
            block.order = getters.getNewSynopsisBlockOrder;

            const result = await axios.post("/synopsis/" + state.currentSynopsis + "/block/", {
                synopsisId: state.currentSynopsis,
                ...block,
            });

            commit("addSynopsisBlock", {
                ...block,
                id: result.data,
            });
        } catch (e) {
            console.warn(e);
        }
    },

    async modifySynopsisBlock({ commit, state }, modifications) {
        try {
            const result = await axios.put("/synopsis/" + state.currentSynopsis + "/block/" + modifications.id, {
                ...modifications,
            });

            commit("modifySynopsisBlock", modifications);
        } catch (e) {
            console.warn(e);
        }
    },

    async removeSynopsisBlock({ commit, state }, id) {
        try {
            await axios.delete("/synopsis/" + state.currentSynopsis + "/block/" + id);

            commit("removeSynopsisBlock", id);
        } catch (e) {
            console.warn(e);
        }
    },

    // SynopsisElement

    async addSynopsisElement({ commit, getters, state }, element) {
        try {
            element.order = getters.getNewSynopsisElementOrder(element.synopsisBlockId);

            const result = await axios.post("/synopsis/" + state.currentSynopsis + "/element/", {
                ...element,
            });

            commit("addSynopsisElement", {
                ...element,
                id: result.data,
            });
        } catch (e) {
            console.warn(e);
        }
    },

    async modifySynopsisElement({ commit, state }, modifications) {
        try {
            const result = await axios.put("/synopsis/" + state.currentSynopsis + "/element/" + modifications.id, {
                ...modifications,
            });

            commit("modifySynopsisElement", modifications);
        } catch (e) {
            console.warn(e);
        }
    },

    async removeSynopsisElement({ commit, state }, id) {
        try {
            await axios.delete("/synopsis/" + state.currentSynopsis + "/element/" + id);

            commit("removeSynopsisElement", id);
        } catch (e) {
            console.warn(e);
        }
    },
};
