// import nanoid from "nanoid";

export default {
    addAlert({ commit }, alert) {
        alert.id = Math.random();

        if (alert.duration > 0) {
            alert.timeout = setTimeout(() => {
                commit("removeAlert", alert.id);
            }, alert.duration);
        }

        commit("addAlert", alert);
    },

    removeAlert({ commit }, id) {
        commit("removeAlert", id);
    },
};
