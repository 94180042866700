import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

// Lang
import fr from "vuetify/es5/locale/fr";

// Icons
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { fr },
        current: "fr",
    },
    rtl: false,

    icons: {
        iconfont: "fa",
    },

    theme: {
        dark: false,

        themes: {
            light: {
                blue: {
                    base: "#8ec6f3",
                    darken1: "#8696E9",
                    darken2: "#1867C0"
                },
                purple: {
                    base: "#b55ec4",
                    lighten1: "#b2a2eb",
                    lighten2: "#e0e0f8",
                },
                pink: {
                    base: "#E9B4DA",
                },
                pinkred: {
                    base: "#FF7382",
                    lighten1: "#FFBFC6",
                    lighten2: "#FBE3E5",
                },
                orange: {
                    base: "#fb8c00",
                    lighten1: "#f7d8b3",
                },
                brown: {
                    base: "#AF9093",
                },
                background: {
                    base: "#f4f9fd",
                },
                red: {
                    base: "#f44336",
                },
                green: {
                    base: "#85c288",
                    darken1: "#5FB44D"
                },
                lightgreen: {
                    base: "#8bd461",
                    lighten1: "#bcfa98"
                },
                grey: {
                    base: "#dbdbdb",
                    darken1: "#737373",
                    darken2: "#313740",
                },
            },
            dark: {
                blue: {
                    base: "#8ec6f3",
                    darken1: "#586778",
                },
                purple: {
                    base: "#AE86B6",
                    lighten1: "#7A709E",
                    lighten2: "#e0e0f8",
                },
                pink: {
                    base: "#AE86B6",
                },
                pinkred: {
                    base: "#C7656F",
                    lighten1: "#FFBFC6",
                    lighten2: "#FBE3E5",
                },
                orange: {
                    base: "#BE6F0C",
                    lighten1: "#CAB397",
                },
                brown: {
                    base: "#AF9093",
                },
                red: {
                    base: "#FEACAC",
                },
                green: {
                    base: "#98C99A",
                },
                lightgreen: {
                    base: "#dfe9e8",
                },
                grey: {
                    base: "#FFFFFF",
                    darken1: "#FFFFFF",
                    darken2: "#FFFFFF",
                },
                white: {
                    base: "#212121"
                },
            },
        },

        options: {
            customProperties: true,
            /* minifyTheme: function(css) {
				return process.env.NODE_ENV === "production"
					? css.replace(/[\s|\r\n|\r|\n]/g, "")
					: css;
			},

			themeCache: {
				get: key => localStorage.getItem(key),
				set: (key, value) => localStorage.setItem(key, value),
			},
			*/
        },
    },
});
