<template>
    <v-container id="alertContainer" v-show="alerts">
        <v-row v-for="alert in alerts" :key="alert.id" dense>
            <v-alert :type="alert.type" :value="true" class="pb-0">
                <h3 v-if="alert.title" v-text="alert.title"></h3>
                <p v-text="alert.message"></p>
            </v-alert>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "PopupAlert",

    computed: {
        ...mapState("global", ["alerts"]),
    },
};
</script>

<style scoped>
#alertContainer {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 30%;
    z-index: 1000;
}

.v-alert {
    position: relative;
    float: right;
}
</style>
