import Vue from "vue";

export default {
    setFormationList(state, formations) {
        state.formations = formations;
    },

    setEducationalList(state, educationals) {
        state.educationalObjectives = educationals;
    },

    setOperationalList(state, operationals) {
        state.operationalObjectives = operationals;
    },

    setSpecificList(state, specifics) {
        state.specificObjectives = specifics;
    },

    addEducationalObjective(state, objective) {
        state.educationalObjectives.push(objective);
    },

    addOperationalObjective(state, objective) {
        state.operationalObjectives.push(objective);
    },

    addSpecificObjective(state, objective) {
        state.specificObjectives.push(objective);
    },

    addFormation(state, formation) {
        state.formations.push(formation);
    },

    modifyFormation(state, modifications) {
        const formationIndex = state.formations.findIndex((obj) => obj.id === state.currentFormation);
        Vue.set(state.formations, formationIndex, { ...state.formations[formationIndex], ...modifications });
    },

    modifyEducationalObjective(state, modifications) {
        const objectiveIndex = state.educationalObjectives.findIndex((obj) => obj.id === modifications.id);
        Vue.set(state.educationalObjectives, objectiveIndex, { ...state.educationalObjectives[objectiveIndex], ...modifications });
    },

    modifyOperationalObjective(state, modifications) {
        const objectiveIndex = state.operationalObjectives.findIndex((obj) => obj.id === modifications.id);
        Vue.set(state.operationalObjectives, objectiveIndex, { ...state.operationalObjectives[objectiveIndex], ...modifications });
    },

    modifySpecificObjective(state, modifications) {
        const objectiveIndex = state.specificObjectives.findIndex((obj) => obj.id === modifications.id);
        Vue.set(state.specificObjectives, objectiveIndex, { ...state.specificObjectives[objectiveIndex], ...modifications });
    },

    removeFormation(state, id) {
        const formationIndex = state.formations.findIndex((obj) => obj.id === id);
        state.formations.splice(formationIndex, 1);
    },

    removeEducationalObjective(state, id) {
        const objectiveIndex = state.educationalObjectives.findIndex((obj) => obj.id === id);
        const eduOrder = state.educationalObjectives[objectiveIndex].order;
        state.educationalObjectives.splice(objectiveIndex, 1);

        for (let index = eduOrder; index < state.educationalObjectives.length; index++) {
            const newEduOrder = state.educationalObjectives[index].order - 1;
            Vue.set(state.educationalObjectives, index, { ...state.educationalObjectives[index], order: newEduOrder });
        }
    },

    removeOperationalObjective(state, id) {
        const objectiveIndex = state.operationalObjectives.findIndex((obj) => obj.id === id);
        const opeOrder = state.operationalObjectives[objectiveIndex].order;
        state.operationalObjectives.splice(objectiveIndex, 1);

        for (let index = opeOrder; index < state.operationalObjectives.length; index++) {
            const newOpeOrder = state.operationalObjectives[index].order - 1;
            Vue.set(state.operationalObjectives, index, { ...state.operationalObjectives[index], order: newOpeOrder });
        }
    },

    removeSpecificObjective(state, id) {
        const objectiveIndex = state.specificObjectives.findIndex((obj) => obj.id === id);
        const speOrder = state.specificObjectives[objectiveIndex].order;
        state.specificObjectives.splice(objectiveIndex, 1);

        for (let index = speOrder; index < state.specificObjectives.length; index++) {
            const newSpeOrder = state.specificObjectives[index].order - 1;
            Vue.set(state.specificObjectives, index, { ...state.specificObjectives[index], order: newSpeOrder });
        }
    },

    setCurrentFormation(state, formationId) {
        state.currentFormation = formationId;
    },

    setVirtualClassesList(state, classes) {
        state.virtualClasses = classes;
    },

    addVirtualClass(state, classObject) {
        state.virtualClasses.push(classObject);
    },

    modifyVirtualClass(state, modifications) {
        const classIndex = state.virtualClasses.findIndex((obj) => obj.id === modifications.id);
        Vue.set(state.virtualClasses, classIndex, { ...state.virtualClasses[classIndex], ...modifications });
    },

    removeVirtualClass(state, id) {
        const classIndex = state.virtualClasses.findIndex((obj) => obj.id === id);
        state.virtualClasses.splice(classIndex, 1);
    },

    setVirtualClassElements(state, elements) {
        state.virtualClassElements = elements;
    },

    modifyVirtualClassElement(state, modifications) {
        const classElementIndex = state.virtualClassElements.findIndex((obj) => obj.id === modifications.id);
        Vue.set(state.virtualClassElements, classElementIndex, { ...state.virtualClassElements[classElementIndex], ...modifications });
    },

    setTeachingProcessesList(state, classes) {
        state.teachingProcesses = classes;
    },

    addTeachingProcess(state, teachingObject) {
        state.teachingProcesses.push(teachingObject);
    },

    modifyTeachingProcess(state, modifications) {
        const teachingIndex = state.teachingProcesses.findIndex((obj) => obj.id === modifications.id);
        Vue.set(state.teachingProcesses, teachingIndex, { ...state.teachingProcesses[teachingIndex], ...modifications });
    },

    removeTeachingProcess(state, id) {
        const teachingIndex = state.teachingProcesses.findIndex((obj) => obj.id === id);
        state.teachingProcesses.splice(teachingIndex, 1);
    },

    setTeachingProcessElements(state, elements) {
        state.teachingProcessElements = elements;
    },

    modifyTeachingProcessElement(state, modifications) {
        const teachingElementIndex = state.teachingProcessElements.findIndex((obj) => obj.id === modifications.id);
        Vue.set(state.teachingProcessElements, teachingElementIndex, { ...state.teachingProcessElements[teachingElementIndex], ...modifications });
    },
};
