export default {
    addAlert(state, alert) {
        state.alerts.push(alert);
    },

    removeAlert(state, id) {
        const alertIndex = state.alerts.findIndex((alert) => alert.id === id);
        state.alerts.splice(alertIndex, 1);
    },
};
