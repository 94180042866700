import Vue from "vue";
import App from "./App.vue";

import store from "./store";
import router from "./router";

import vuetify from "./plugins/vuetify";

import "./utils/filters";

Vue.config.productionTip = false;

const app = new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
});

app.$mount("#app");