const axios = require("axios");
const router = require("@/router/index.js").default;

axios.defaults.baseURL = process.env.VUE_APP_API_SERVER_URL;

import { default as HTTP } from "@/utils/http";
import jwt_decode from "jwt-decode";

export default {
    async login({ commit, dispatch }, loginForm) {
        try {
            const res = await HTTP.post("/auth/login", loginForm);

            dispatch("setAutoRefresh");

            commit("setJwtToken", res.data);
            commit("setUserData", jwt_decode(res.data));

            axios.defaults.headers.common = { Authorization: `Bearer ${res.data}` };

            HTTP.setHeaders({
                common: {
                    Authorization: `Bearer ${res.data}`,
                }
            });

            return res;
        } catch (error) {
            return error.response || error;
        }
    },

    async register({ commit }, registerForm) {
        try {
            return await HTTP.post("/auth/register", registerForm);
        } catch(error) {
            return error.response || error;
        }
    },

    askResetPassword({ commit }, email) {
        return new Promise((resolve, reject) => {
            axios
                .post("/auth/password/forgot", email)
                .then((response) => {
                    resolve(response.status);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    resetPassword({ commit }, passwordResetInfo) {
        return new Promise((resolve, reject) => {
            axios
                .post("/auth/password/reset", passwordResetInfo)
                .then((response) => {
                    resolve(response.status);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    disconnect({ commit }) {
        commit("setRefreshTask", null);
        commit("clearToken");
        router.push({ name: "Login" });
    },

    async refreshToken({ dispatch, commit }) {
        try {
            const result = await axios.post("/auth/refresh");

            commit("setJwtToken", result.data);

            commit("setUserData", jwt_decode(result.data));
            axios.defaults.headers.common = { Authorization: "Bearer " + result.data };

            HTTP.setHeaders({
                common: {
                    Authorization: `Bearer ${result.data}`,
                }
            });
        } catch (e) {
            console.warn(e);
            dispatch("disconnect");
        }
    },

    setAutoRefresh({ commit, dispatch }) {
        const refreshTak = setInterval(() => {
            dispatch("refreshToken");
        }, 60000);

        commit("setRefreshTask", refreshTak);
    },

    clearToken({ commit }) {
        commit("clearToken");
    },

    async modifyUser({ state, dispatch }, modifications) {
        try {
            const res = await HTTP.put(`/users/${state.user.id}`, modifications);

            return res;
        } catch (error) {
            return error.response || error;
        }
    },

    async addCoworker({ state }) {
        try {
            return await HTTP.post(`/users/${state.user.id}/coworker`);
        } catch (error) {
            return error.response || error;
        }
    },

    async getCoworkers({ state, commit }) {
        try {
            const coworkersRes = await HTTP.get(`/users/${state.user.id}/coworker`);
            commit("setCoworkers", coworkersRes.data);
        } catch (err) {
            return err.response || err;
        }
    },

    async setCoworker({ state, commit }, coworker) {
        try {
            return await HTTP.put(`/users/${state.user.id}/coworker/${coworker.id}`, coworker);
        } catch (err) {
            return err.response || err;
        }
    },

    async removeCoworker({ state, commit }, coworkerId) {
        try {
            return await HTTP.delete(`/users/${state.user.id}/coworker/${coworkerId}`);
        } catch (err) {
            return err.response || err;
        }
    },

    async sendInviteMail({ state }, coworkerId) {
        try {
            return await HTTP.post(`/users/${state.user.id}/coworker/${coworkerId}/invite`);
        } catch (err) {
            return err.response || err;
        }
    }
};
